/* stile pagina single.jsx */

.h2-blog{
    color: #dbbe6f;
}

.h3-blog{
    font-weight: bold;
}

.img-left-blog{
    float: left;
    width: 100%;
    border-radius: 10px;
    margin-bottom: 20px;
}

.img-right-blog{
    float: right;
    width: 100%;
    border-radius: 10px;
    margin-bottom: 20px;
}

@media (min-width: 768px) { 

    .h2-blog{
        color: #dbbe6f;
    }

    .h3-blog{
        font-weight: bold;
    }

    .img-left-blog{
        float: left;
        width: 50%;
        border-radius: 10px;
        margin-right: 50px;
    }

    .img-right-blog{
        float: right;
        width: 50%;
        border-radius: 10px;
        margin-left: 50px;
    }

}