* {
  font-family: 'PT Serif', serif;
}
h1 {
 font-family: 'Playfair Display', serif;
}
h2 {
 font-family: 'Playfair Display', serif;
}
h3 {
 font-family: 'Playfair Display', serif;
}
h4 {
 font-family: 'Playfair Display', serif;
}
h5 {
 font-family: 'Playfair Display', serif;
}
h6 {
 font-family: 'Playfair Display', serif;
}

.text-default{
  font-family: 'Playfair Display', serif;
  color: #DBBE6F;
  font-weight: 600;
}

.text-animate-gold {
  font-family: 'Playfair Display', serif;
  position: relative;
  background: linear-gradient(90deg, rgba(186,148,62,1) 0%, rgba(236,172,32,1) 20%, rgba(186,148,62,1) 39%, rgba(249,244,180,1) 50%, rgba(186,148,62,1) 60%, rgba(236,172,32,1) 80%, rgba(186,148,62,1) 100%);
  -webkit-background-clip: text;  
  -webkit-text-fill-color: transparent;	
  animation: shine 3s infinite;
  background-size: 300%;
  background-position: left;
}
/* 
body{
  background-color: #000000!important;
}

.navbar-dark{
  background-color: #ffffff80!important;
}
.text-dark{
  color: #f8f9fa!important;
}
.text-dark-mode{
  color: #212529!important;
}
.text-light{
  color: #212529!important;
}
.text-light-dark-mode{
  color: #f8f9fa!important;
}
.bg-light{
  background-color: rgba(254, 254, 254, 0.2)!important;
}
.bg-dark{
  background-color: #f8f9fa!important;
} */
.shadow-light-top{
  box-shadow: inset 0px 50px 50px -50px rgba(255, 255, 255, 0.5);
}
.shadow-light-bottom{
  box-shadow: inset 0px -50px 50px -50px rgba(255, 255, 255, 0.5);
}
.shadow{
  box-shadow: 0 .5rem 1rem rgba(255, 255, 255, 0.15)!important;
}

/* .bg-secondary{
  background-color: #424242!important;
} */

.bg-secondary-dark-mode{
  background-color: #616161!important;
  color: #f8f9fa!important;
}

.text-gold-dark-mode{
  color: #dbbe6f!important;
}

.l-size{
  height: 70px!important;
}

/* LoadingPage.css */

.loading-page {
  /* Existing styles for centering and padding */
background-color: #000;
  opacity: 1;
  transition: opacity 0.5s ease-in-out; /* Add a smooth opacity transition */
}

.loading-page.hidden {
  opacity: 0;
}

.icon_menu span {
  display: block;
  background-color: #dbbe6f;
  width: 100%;
  height: 4px;
  margin-top: -2px;
  position: absolute;
  left: 0;
  top: 50%;
  transition: all 0.2s ease;
}

  .icon_menu {
    height: 32px;
    width: 43px;
    margin: 3%;
    position: relative;
    display: inline-block;
    vertical-align: middle;
    cursor: pointer;
  }
  
  .icon_menu:before,
  .icon_menu:after {
    content: '';
    display: block;
    background-color: #dbbe6f;
    width: 100%;
    height: 4px;
    position: absolute;
    left: 0;
    transform-origin: center center;
    transform: rotate(0deg);
    transition: all 0.2s ease;
  }
  
  .icon_menu:before {
    top: 2px;
    margin-top: -2px;
  }
  
  .icon_menu:after {
    bottom: 2px;
    margin-bottom: -2px;
  }
  
   .icon_menu[aria-expanded="true"] span {
    display: none;
  }  
  
   .icon_menu[aria-expanded="true"]:before {
    top: 50%;
    transform: rotate(45deg);
  }
  
   .icon_menu[aria-expanded="true"]:after {
    bottom: 50%;
    transform: rotate(-45deg);
  }
  

  .swiper {
    width: 100%;
    height: 100%;

  }
  
  .swiper-slide {
    font-size: 18px;
    color: #fff;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    padding: 40px 60px;
  }
  
  .parallax-bg {
    position: absolute;
    left: 0;
    top: 0;
    width: 130%;
    height: 100%;
    -webkit-background-size: cover;
    background-size: cover;
    background-position: center;
  }
  
  .swiper-slide .title {
    font-size: 41px;
    font-weight: 300;
  }
  
  .swiper-slide .subtitle {
    font-size: 21px;
  }
  
  .swiper-slide .text {
    font-size: 14px;
    max-width: 400px;
    line-height: 1.3;
  }

  .background-clienti,
  .background-collaboratori {
   position: relative;
  }
  
  .background-clienti::before,
  .background-collaboratori::before {
   content: "";
   position: absolute;
   top: 0;
   right: 0;
   bottom: 0;
   left: 0;
   border-radius: 25px;
   background-color: rgb(255, 255, 255);
   opacity: 0.8;
  }
  
  .background-clienti .card-body,
  .background-collaboratori .card-body {
   position: relative;
   z-index: 2;
   border-radius: 25px;
  }

  .bg-default{
    background-color: #DBBE6F;
  }

  .swiper-pagination-bullet{
    background: #ffffff;
    opacity:  var(--swiper-pagination-bullet-inactive-opacity, 1);
    border: 1px solid rgb(194, 194, 194);
  }
  .swiper-pagination-bullet-active{
    background: #DBBE6F;
    opacity:  var(--swiper-pagination-bullet-inactive-opacity, 1);
    border: 1px solid rgb(194, 194, 194);
  }

  .swiper-button-prev{
    color: #DBBE6F;

  }
  
  .swiper-button-next{
    color: #DBBE6F;
  }
  
  .swiper-button-next:after,.swiper-button-prev:after{
    font-family:swiper-icons;
    font-size:medium;
    font-weight: bolder;
    text-transform:none!important;
    letter-spacing:0;
    font-variant:initial;
    line-height:1
  }
 
  .bg-marmo:after{
    content:"";
    position:fixed; 
    top:0;
    height:100vh;
    left:0;
    right:0;
    z-index:-1;
    background: url(./assets/home/bgmarmo.webp) center center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }

  .custom-bg-icon{
    border-radius: 150px 150px 150px 150px;
  }

  .bg-fixing-price-lg{
    background: rgb(248,224,95);
    background: linear-gradient(90deg, rgba(248,224,95,1) 0%, rgba(248,223,94,1) 25%, rgba(210,149,32,1) 75%);
  }

  @media (min-width: 768px) { 
    .nav-link{
      font-size: 0.76rem;
    }
   }

  @media (min-width: 1200px) { 
    .nav-link{
      font-size: medium;
    }
   }

.fa-phone-volume{
  rotate: -150deg;
}

.expanded{
 overflow: visible;
}

.collapsed{
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.collapsed1{
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

@media (min-width: 768px) { 
  .collapsed1{
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }
    }
.collapsed2{
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.rounded-25{
  border-radius: 25px;
}

.modal {
  --bs-modal-width: 1050px!important; 
  position: fixed;
  --bs-modal-header-border-color: #DBBE6F;
  --bs-modal-header-border-width: 1px;
}

/* contact form */

.contact-form {
  border-radius: 0.5vh;
  background-color: rgb(255, 255, 255);
  padding: 20px;
}

/* contact form */  
/* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 25px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}
#prz{
  transform: rotate(4deg);
  transition: 1000ms;
}
.slider:before {
  position: absolute;
  content: "";
  height: 21px;
  width: 21px;
  left: 2px;
  bottom: 2px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

/* input:checked + .slider {
  background-color: #DBBE6F;
  ;
}

input:focus + .slider {
  box-shadow: 0 0 1px #DBBE6F;
}

input:checked + .slider:before {
  -webkit-transform: translateX(25px);
  -ms-transform: translateX(25px);
  transform: translateX(25px);
} */
.modal-body {
  height: 60% !important;
  width: 100% !important;
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}



.form-control.is-invalid, .was-validated .form-control:invalid{
  border: 1.5px solid red;
 }


 /* popup */

   /* contact form */  
/* The switch - the box around the slider */
.switch-popup {
  position: relative;
  display: inline-block;
  width: 48.5px;
  height: 22px;
}

/* Hide default HTML checkbox */
.switch-popup input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider-popup {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}
#prz{
  transform: rotate(4deg);
  transition: 1000ms;
}
.slider-popup:before {
  position: absolute;
  content: "";
  height: 19px;
  width: 19px;
  left: 1.5px;
  bottom: 1.32px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

/* input:checked + .slider-popup {
  background-color: #DBBE6F;
  ;
}

input:focus + .slider-popup {
  box-shadow: 0 0 1px #DBBE6F;
}

input:checked + .slider-popup:before {
  -webkit-transform: translateX(27px);
  -ms-transform: translateX(27px);
  transform: translateX(27px);
} */
.modal-body {
  height: 60% !important;
  width: 100% !important;
}

/* Rounded sliders */
.slider-popup.round {
  border-radius: 34px;
}

.slider-popup.round:before {
  border-radius: 50%;
}



.form-control.is-invalid, .was-validated .form-control:invalid{
  border: 1.5px solid red;
 }
 
 .btn-default:disabled{
  background-color: #DBBE6F;
  color: #fff;
  width: auto!important;
  /* border-radius: 50px; */
  border: none;
  font-weight: 700;
}

 .btn-default{
  background-color: #DBBE6F;
  color: #fff;
  width: auto!important;
  /* border-radius: 50px; */
  border: none;
  font-weight: 700;
}
.btn-default:focus,
.btn-default:hover
{
  background-color: #DBBE6F;
  color: #fff;
  transition: 0.5s;
  transform: scale(1.05);
  width: auto!important;
  /* border-radius: 50px 50px 50px 50px; */
  font-weight: 700;
}
.btn-default:active
{
  background-color: #DBBE6F;
  color: #fff;
  transition: 0.5s;
  transform: scale(0.99);
  width: auto!important;
  /* border-radius: 50px 50px 50px 50px; */
  border: none;
  font-weight: 700;
}

.btn-default-cat{
  width: 100%!important;
  background-color: #DBBE6F;
  color: #fff;
  /* border-radius: 50px; */
  border: none;
  font-weight: 700;
}
.btn-default-cat:focus,
.btn-default-cat:hover
{
  background-color: #DBBE6F;
  color: #fff;
  transition: 0.5s;
  transform: scale(1.05);
  width: 100%!important;
  /* border-radius: 50px 50px 50px 50px; */
  font-weight: 700;
}
.btn-default-cat:active
{
  background-color: #DBBE6F;
  color: #fff;
  transition: 0.5s;
  transform: scale(0.99);
  width: 100%!important;
  /* border-radius: 50px 50px 50px 50px; */
  border: none;
  font-weight: 700;
}


#map {
  height: 400px; /* The height is 400 pixels */
  width: 100%; /* The width is the width of the web page */
}

.text-link{
  color: darkblue;
}
.text-link::after{
  color: darkblue;
}
.text-link:hover{
  color: darkblue;
}

.iframe-responsive-size{
  height: 206px;
}

@media (min-width: 768px) { 
  .iframe-responsive-size{
    height: 270px;
    width: 500px;
  } 
 }

@media (min-width: 992px) { 
  .iframe-responsive-size{
    height: 320px;
    width: 600px;
  }
 }

@media (min-width: 1200px) { 
  .iframe-responsive-size{
    height: 490px;
    width: 900px;
  }
 }

 .large-header {
  position: relative;
  width: 100%;
  background: #333;
  overflow: hidden;
  background-size: cover;
  background-position: center center;
  z-index: 1;
}

/* .bg-oro:after{
  content:"";
  position:fixed; 
  top:0;
  height:100vh;
  left:0;
  right:0;
  z-index:-1;
  background: url(./components/assets/img/landingCIG/bg-investire-noi.jpg) center left;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}
 */

.piani-rounded{
  border-radius: 25px 25px 25px 25px;
}

.button-rounded{
  border-radius: 10px 10px 10px 10px;
}

/* input[type="radio"] {
  display: none;
  color:  rgba(var(--bs-dark-rgb), var(--bs-text-opacity)) ;
} */

.tab {
  position: center;
  padding: 0.75rem;
  cursor: pointer;
  z-index: 2;
  height: 54px;
  width: 200px;
  transition: color 0.2s ease-in;
}

.glider {
  position: absolute;
  display: flex;
  left: 1rem;
  height: 54px;
  width: 45%;
  background-color: #fff;
  /* background: white; */
  box-shadow: inset -3px -3px 8px rgba(255, 255, 255, 0.9),
    inset 3px 3px 8px #576179;
  z-index: 1;
  border-radius: 50px;
  transition: transform 0.2s ease-in;
  border: 1px solid rgb(197, 197, 197);
}

/* input[type="radio"]:checked + label {
  color: rgb(219, 190, 111);
} */

#radio1:checked ~ .glider {
  transform: translateX(0);
}

input[id="radio-2"]:checked ~ .glider {
  transform: translateX(100%);
}

.btn-social {
  height: 2.5rem;
  width: 2.5rem;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  border-radius: 100%;
}

/* radio button check box */

.form-check-input-custom[type=checkbox] {
  border-radius: 0.25em;
}

.form-check-input-custom {
  --bs-form-check-bg: var(--bs-body-bg);
  width: 1em;
  height: 1em;
  margin-top: 0.25em;
  vertical-align: top;
  background-color: var(--bs-form-check-bg);
  background-image: var(--bs-form-check-bg-image);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  border: var(--bs-border-width) solid var(--bs-border-color);
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  -webkit-print-color-adjust: exact;
  color-adjust: exact;
  print-color-adjust: exact;
}

.form-check-input-custom:checked {
  background-color: #dbbe6f;
  border-color: #dbbe6f;
}

.form-check-input-custom:checked[type=checkbox] {
  --bs-form-check-bg-image: url(data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='m6 10 3 3 6-6'/%3e%3c/svg%3e);
}

/* radio button check box */


/* editor style */
.ql-container.ql-snow {
  height: 308px !important;
}
.ql-editor {
  overflow-y: hidden;
}

/* editor style */

/* search bar */

.inputContainer {
  position: relative;
}

.inputContainer input {
  padding-right: 30px;
}

.searchIcon {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
}

/* search bar */
.heart-custom{
  z-index: 4;
  position: fixed;
  right: 1rem;
  bottom: 6rem;
  left: auto;
  width: 4rem;
}

.first-button {
  z-index: 4;
  position: fixed;
  right: 1rem;
  bottom: 1rem;
  left: auto;
  width: 4rem;
}

.custom-overflow{
  overflow: visible!important;
}

.show1 {
  transform: translateX(-10rem);
  transition: transform 1s;
  z-index: 1;
  position: fixed;
  right: 4.5rem;
  bottom: 1.45rem;
  left: auto;
  width: 4rem;
}
.show2 {
  transform: translateX(-5.75rem);
  transition: transform 1s;
  z-index: 2;
  position: fixed;
  right: 4.5rem;
  bottom: 1.45rem;
  left: auto;
  width: 4rem;
}

.show3 {
  transform: translateX(-1.5rem);
  transition: transform 1s;
  z-index: 3;
  position: fixed;
  right: 4.5rem;
  bottom: 1.45rem;
  left: auto;
  width: 4rem;
}

@media (max-width: 767.98px) {
  .show1 {
  transform: translateX(-10rem);
  transition: transform 1s;
  z-index: 1;
  position: fixed;
  right: 4.5rem;
  bottom: 1.45rem;
  left: auto;
  width: 4rem;
}
.show2 {
  transform: translateX(-5.75rem);
  transition: transform 1s;
  z-index: 2;
  position: fixed;
  right: 4.5rem;
  bottom: 1.45rem;
  left: auto;
  width: 4rem;
}

.show3 {
  transform: translateX(-1.5rem);
  transition: transform 1s;
  z-index: 3;
  position: fixed;
  right: 4.5rem;
  bottom: 1.45rem;
  left: auto;
  width: 4rem;
}
}

.hide1 {
  transform: translateX(100%);
  transition: transform 1s;
  z-index: 1;
  position: fixed;
  right: 4.5rem;
  bottom: 1.45rem;
  left: auto;
  width: 4rem;
}
.hide2 {
  transform: translateX(100%);
  transition: transform 1s;
  z-index: 2;
  position: fixed;
  right: 4.5rem;
  bottom: 1.45rem;
  left: auto;
  width: 4rem;
}
.hide3 {
  transform: translateX(100%);
  transition: transform 1s;
  z-index: 3;
  position: fixed;
  right: 4.5rem;
  bottom: 1.45rem;
  left: auto;
  width: 4rem;
}

.bg-facebook{
  background-color: #3b5998!important;
}
.bg-linkedin{
  background-color: #007bb6!important;
}
.bg-whatsapp{
  background-color: #25D366!important;
}

 .rounded-circle1 {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  }
 
 @media (min-width: 768px) {
  .rounded-circle1 {
    width: 50px;
    height: 50px;
    border-radius: 50%;
   }
 }

/* share button */

.react-autosuggest__container {
  position: relative;
}

.react-autosuggest__input {
  width: 240px;
  height: 30px;
  padding: 10px 20px;
  font-family: Helvetica, sans-serif;
  font-weight: 300;
  font-size: 16px;
  border: 1px solid #d6d6d6;
  border-radius: 10px!important;
}

.react-autosuggest__input--focused {
  outline: none;
}

.react-autosuggest__input--open {
  border-bottom-left-radius: 10px!important;
  border-bottom-right-radius: 10px!important;
}

.react-autosuggest__suggestions-container {
  display: none;
}

.react-autosuggest__suggestions-container--open {
  display: block;
  position: absolute;
  top: 51px;
  width: 100%;
  border: 1px solid #d6d6d6;
  background-color: #fff;
  font-family: Helvetica, sans-serif;
  font-weight: 300;
  font-size: 16px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  z-index: 2;
}

.react-autosuggest__suggestions-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.react-autosuggest__suggestion {
  cursor: pointer;
  padding: 10px 20px;
}

.react-autosuggest__suggestion--highlighted {
  background-color: #ddd;
}

.parallax-bg {
  position: absolute;
  left: 0;
  top: 0;
  width: 130%;
  height: 100%;
  -webkit-background-size: cover;
  background-size: cover;
  background-position: center;
}

.icon-container {
  display: inline-block;
  width: 50px; /* Imposta la larghezza del quadrato */
  height: 50px; /* Imposta l'altezza del quadrato */
  text-align: center; /* Centra l'icona orizzontalmente */
  line-height: 50px; /* Centra l'icona verticalmente */
  border-radius: 50%;
  background-color: #DBBE6F;
}

@media (min-width: 1200px) { 
  .icon-left {
    float: left;
    margin-right: 10px;
  }  
}

.article-container {
  display: flex; /* Utilizza un layout flessibile per posizionare i contenuti */
}

.text-container {
  flex: 1; /* Il contenitore del testo occupa tutto lo spazio rimanente */
}

.image-container {
  position: relative; /* Imposta il posizionamento relativo per consentire il posizionamento dell'immagine */
  float: right; /* Posiziona l'immagine a destra */
  margin-left: 20px; /* Aggiungi un margine a sinistra per separare l'immagine dal testo */
  width: 300px; /* Imposta la larghezza desiderata per l'immagine */
}

.image-container img {
  max-width: 100%; /* Assicurati che l'immagine si adatti al contenitore */
  height: auto; /* Imposta l'altezza dell'immagine in base alla larghezza */
  float: left; /* Posiziona l'immagine a sinistra all'interno del contenitore */
  shape-outside: circle(); /* Opzionale: se desideri dare una forma specifica all'immagine */
  margin: 0 20px 20px 0; /* Opzionale: aggiungi margini per distanziare l'immagine dal testo */
}

 /* popup */

   /* contact form */  
/* The switch - the box around the slider */
.switch-popup {
  position: relative;
  display: inline-block;
  width: 48.5px;
  height: 22px;
}

/* Hide default HTML checkbox */
.switch-popup input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* overlay text */
.text-overlay {
  position: absolute;
  top: 47vw;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
  text-shadow: 8px 5px 10px black;
  letter-spacing: 0em;
  transition: letter-spacing 0.5 ease-in-out; 
  animation: stretch 2s ease-in-out forwards;
  font-size: 6vw;
  line-height: 1.1em;
  /* animation-delay: 0.2s; */
}

.pop-up-container{
  position: relative;
}
.text-shadow-none{
  text-shadow: none!important;
}
.text-overlay-2 {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
@keyframes stretch {
  from {
    letter-spacing: 0.15em;
  }
  to {
    letter-spacing: 0em;
  }
} 

@media (min-width: 992px) { 

  .margin-left-custom{
    margin-left: 59.5%;
  }

}

@media (min-width: 1200px) { 

  .margin-left-custom{
    margin-left: 44%;
  }

}



.mySwiper{
  margin-top: 0px
}

.current-location-btn {
  background-color: #fff;
  border: none;
  color: #333;
  font-size: 14px;
  padding: 8px 0px;
  margin: 10px;
  cursor: pointer;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  width: 40px;
}

.sede-btn {
  background-color: #fff;
  border: none;
  color: #333;
  font-size: 14px;
  padding: 10px 0px;
  margin: 10px;
  cursor: pointer;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  width: 40px;
  top: 120px!important;
}

.custom-margin-top{
  margin-top: 6rem;
}
@media (min-width: 992px) { 
  .custom-margin-top{
    margin-top: 6.5rem;
  }
}

/* contact form */  
/* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 25px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}
#prz{
  transform: rotate(4deg);
  transition: 1000ms;
}
.slider:before {
  position: absolute;
  content: "";
  height: 21px;
  width: 21px;
  left: 2px;
  bottom: 2px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slider {
  background-color: #DBBE6F;
  ;
}

input:focus + .slider {
  box-shadow: 0 0 1px #DBBE6F;
}

input:checked + .slider:before {
  -webkit-transform: translateX(25px);
  -ms-transform: translateX(25px);
  transform: translateX(25px);
}
.modal-body {
  height: 60% !important;
  width: 100% !important;
}

.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

/* Rounded sliders */


.close-button {
  position: absolute;
  top: 0px;
  right: 10px;
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
}

.close-icon {
  font-size: 20px;
  color: #000;
}

.icon360 {
  position: relative;
  top: -15px;
  transform: none;
  width: 80px;
  height: 60px;
  opacity: 50%;
  margin-right: 0px;
}

@media (min-width: 768px) { 
  .icon360 {
    position: relative;
    top: -3%;
    transform: none;
    width: 100px;
    height: 0px;
    opacity: 50%;
    margin-right: 11px;
  }
}

.sc-aXZVg {
  max-width: 100%!important;
  height: auto; 
}

.custom-logo-size{
  width: 15rem;
  margin-inline: auto;
}
@media (min-width: 768px) { 
  .custom-logo-size{
    width: 20rem;
    margin-inline: auto;
  }
}

@media (min-width: 576px) { 
  .custom-rounded-home {
    border-radius: .375rem;
  }
}

.inputContainer input {
  padding-right: 0px;
}

.fs-custom-card {
  font-size: 1rem;
}

  .fs-5-psc{
    font-size:1rem !important;
  }

@media (min-width: 1200px) { 
  .fs-5-psc{
    font-size: 1rem!important;
  }
}

@media (min-width: 1400px) { 
  .fs-5-psc{
    font-size: 1.25rem!important;
  }
}

/* LoadingPage.css */
.bg-loading{
 background-color: rgb(254,252,254);
}
.video-loading-settings{
  width: 250%;
}

@media (min-width: 768px) {
  .video-loading-settings{
    width: 200%;
  }
}

@media (min-width: 992px) { 
  .video-loading-settings{
    width: 150%;
  }
}

@media (min-width: 1200px) {
  .video-loading-settings{
    width: 100%;
  }
}

@media (min-width: 1400px) {
  .video-loading-settings{
    width: 70%;
  }
}

@keyframes shine {
  0% {
    background-position: left;
  }
  100% {
    background-position: right;
  }
}

.border-gold{
  position: relative;
  background: linear-gradient(90deg, rgba(186,148,62,1) 0%, rgba(236,172,32,1) 20%, rgba(186,148,62,1) 39%, rgba(249,244,180,1) 50%, rgba(186,148,62,1) 60%, rgba(236,172,32,1) 80%, rgba(186,148,62,1) 100%);
  padding: 3px;
  animation: shine 3s infinite;
  background-size: 300%;
  background-position: left;
}

#brands{
  transition: top 1s;
}

.navbar-dark{
  background-color: rgb(0, 0, 0)!important;
}

.navbar-main{
  transition: top 1s;
  top: 0px;
  z-index: 888;
}

#mainNav{
  transition: top 1s;
}

#goldPrice{
  transition: top 1s;
}

@media (min-width: 992px) {
  .brands-navbar{
    top: -72px;
    transition: top 1s;
  }
  .navbar-main{
    transition: top 1s;
    top: 0px;
  }
}

#tsparticles {
  height: 40rem;
  /* background-image: url("./assets/newsletter/oro-prestige.png"); */
  background-size: 80rem;
  background-repeat: no-repeat;
  background-position: center;
}

.glowing-light {
  width: 50%;
  height: 50px;
  border-radius: 15px;
  box-shadow: 0 -20px 70px #e1d0a4, -50px 0 70px #e1d0a4, 50px 0 70px #e1d0a4;
  animation: glowing 2s ease-in-out infinite;
  position: absolute; /* Position the light in the center of the container */
  transform: translate(50%, -3rem); /* Center the light precisely */
  z-index: 0; /* Set a higher z-index to place the light above the particles */
  background: #dbbe6f;
  background-size: 300%;
  background-position: left;
}

.glowing-light-bottom{
  width: 100%;
  height: 6rem;
  transform: translate(0rem, -0rem);
  background: #010101;
  background-size: 300%;
  background-position: left;
}


@keyframes glowing {
  0% {
    box-shadow: 0 -20px 80px #e1d0a4, -50px 0 70px #e1d0a4, 50px 0 70px #e1d0a4;
  }
  50% {
    box-shadow: 0 -0px 80px #e1d0a4, -30px 0 70px #e1d0a4, 30px 0 70px #e1d0a4;
  }
  100% {
    box-shadow: 0 -20px 80px #e1d0a4, -50px 0 70px #e1d0a4, 50px 0 70px #e1d0a4; 
  }
}

.btn-call{
  margin-right: 1rem;
  margin-bottom: 1rem;
}

.gallery-search-bar{
  width: 19rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
}
@media (min-width: 768px) {
  .gallery-search-bar{
    width: 30rem;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    padding-left: 1rem;
  }
}
@media (min-width: 1200px) {
  .gallery-search-bar{
    width: 50rem;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    padding-left: 1rem;
  }
}

.full-screen-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgb(0, 0, 0);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.full-screen-overlay img {
  max-width: 100%;
  max-height: 100%;
}

.background-walter {
  position: relative;
}

.background-walter::after {
  content: "";
  background-image: url('./assets/team/pietro-bg.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.5;
  z-index: -1; 
}

.background-pietro {
  position: relative;
}

.background-pietro::after {
  content: "";
  background-image: url('./assets/team/walter-bg.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.5;
  z-index: -1; 
}

.background-sfumato-top{
  background: linear-gradient(to bottom, rgba(0, 0, 0, 1), transparent);
}

.background-sfumato-bottom{
  background: linear-gradient(to top, rgba(0, 0, 0, 1), transparent);
}

.bg-animate-gold {
  position: relative;
  background: linear-gradient(90deg, rgba(186,148,62,1) 0%, rgba(236,172,32,1) 20%, rgba(186,148,62,1) 39%, rgba(249,244,180,1) 50%, rgba(186,148,62,1) 60%, rgba(236,172,32,1) 80%, rgba(186,148,62,1) 100%);
  
  -webkit-text-fill-color: transparent;	
  animation: shine 3s infinite;
  background-size: 300%;
  background-position: left;
}

.border-custom-transparent{
  border: 2px solid transparent;
}

.loader {
  height: 150px;
width: 100px;
border-radius: 55px 55px 10px 10px;
position: relative;
background: #FF3D00;

background-image: linear-gradient(0deg,
#f63a99 25%,
#30dcf6 25%,
#30dcf6 25%,
#30dcf6 50%,
#f2d200 50%,
#f2d200 50%,
#f2d200 75%,
#70ca5c 75%);
background-position: 0px 0px;
background-size: auto 175px;
background-repeat: repeat-y;
animation: colorShift 6s linear infinite;

}
.loader:before {
content: '';
position: absolute;
left: 10px;
bottom: 15px;
width: 15px;
height: 100px;
border-radius: 50px;
background: rgba(255, 255, 255, 0.5);

}

.loader:after {
content: "";
position: absolute;
left: 50%;
top: 100%;
transform: translate(-50% , 0);
box-shadow: 0 15px 2px rgba(0, 0, 0, 0.25) inset;
width: 32px;
height: 45px;
background: #E09C5F;
border-radius: 0 0 12px 12px;
}

@keyframes colorShift {
to {    background-position: 0 175px}
}

/* Add these styles to your CSS file */

/* Full-screen loading overlay styles */
.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgb(0, 0, 0); /* Semi-transparent black background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999; /* Ensure it's above other elements */
  color: #fff; /* Text color */
  overflow: hidden; /* Prevent scrolling while the overlay is displayed */
  position: fixed; /* Fixed position to cover entire viewport */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 1; /* Initially fully visible */
  transition: opacity 1s ease-in-out; /* Add a smooth fade-out transition */
}

.loading-overlay.hidden {
  opacity: 0; /* When hidden, fully transparent */
  pointer-events: none; /* Prevent interactions with hidden elements */
}

/* Loading spinner styles (optional) */
.loading-spinner {
  border: 4px solid rgba(255, 255, 255, 0.3); /* Change color and size as needed */
  border-top: 4px solid #fff; /* Change color and size as needed */
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite; /* Add a spinning animation */
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

/* Hide the video when loading */
.video-container.hidden {
  display: none;
}

/* Style the video container as needed */
.video-container {
  position: relative;
  width: 100%;
  max-width: 100%;
  overflow: hidden;
  z-index: 1; /* Ensure video is below the loading overlay */
}

/* Style the video as needed */
video {
  width: 100%;
  height: auto;
  display: block;
}

#callToAction{
  z-index: 888;
}

.loading-button{
  margin-top: 60vh;
  transition: opacity 1s ease-in; /* Add a smooth fade-out transition */
  animation: pulsate 1.5s infinite;
}

.opacity-custom-08{
  opacity: 0.8;
}

.opacity-custom-0{
  opacity: 0;
}

.custom-video-controls {
  top: 40;
  left: 50%; /* Move it to the horizontal center */
  transform: translateX(50%, 50%); /* Center horizontally */
  z-index: 2000; /* Ensure it's above the video */
}

@keyframes pulsate {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}



/* Loading spinner styles */
.loading-spinner {
  border: 3px solid rgba(255, 255, 255, 0.1); /* Change color and size as needed */
  border-top: 3px solid #dbbe6f; /* Change color and size as needed */
  border-radius: 50%;
  width: 40vh; /* Adjust the width for the larger spinner */
  height: 40vh; /* Adjust the height for the larger spinner */
  animation: spin 1.5s linear infinite; /* Add a spinning animation */
  position: fixed;
  transform: translate(-50%, -50%);
  z-index: 9999; /* Ensure it's above other elements */
  margin: 0; /* Add this to remove any default margins */
  padding: 0; /* Add this to remove any default padding */
  box-sizing: border-box; /* Add this to ensure sizing includes borders */
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.img-logo-loading{
  width: 20vh;
}

